<template>
  <div class="d-flex" style="gap: 2px">
    <Star v-for="(fillPercent, i) in getPerStarRating" :key="'star' + i" :fillPercent="fillPercent" />
    <div class="ml-2" v-if="!removeAverage">{{ rating.toPrecision(2).substring(0, 3) }}</div>
  </div>
</template>
<script>
import Star from '@/components/common/star';

export default {
  components: {
    Star,
  },
  props: {
    rating: { type: Number, required: true },
    removeAverage: { type: Boolean, default: false },
  },
  computed: {
    getPerStarRating() {
      let localRating = this.rating;
      const stars = Array(5).fill(0);
      for (const i in stars) {
        stars[i] = localRating >= 1 ? 1 : Math.round(localRating * 100) / 100;
        localRating = Math.max(localRating - 1, 0);
      }

      return stars;
    },
  },
};
</script>
