<template>
  <b-modal id="createUmfrageModal" title="Erstelle eine neue Umfrage" @hide="onHide">
    <b-form-group label="Teams">
      <Multiselect
        v-model="teamsSelected"
        label="text"
        track-by="value"
        :options="teamOptions"
        selectLabel=""
        selectGroupLabel=""
        deselectLabel=""
        deselectGroupLabel=""
        selectedLabel=""
        placeholder="Wähle Teams aus..."
        :multiple="true"
      />
    </b-form-group>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        :disabled="disableSichernButton"
        :variant="disableSichernButton ? 'secondary' : 'success'"
        @click="createUmfrage(hide)"
      >
        Speichern
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { CREATE_UMFRAGE, GET_UMFRAGEN } from '../../core/umfragen/umfragen.module';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      teamsSelected: '',
    };
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
    }),
    disableSichernButton() {
      return this.teamsSelected.length === 0;
    },
    teamOptions() {
      const duplicateDepartments = this.users
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ department }) => department);
      return [...new Set(duplicateDepartments)]
        .filter(dep => dep !== null)
        .map(department => ({ value: department, text: department }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  methods: {
    createUmfrage(hide) {
      if (this.teamsSelected.length > 0) {
        const teamsSelectedFormatted = this.teamsSelected.map(item => item.value);
        this.$store.dispatch(CREATE_UMFRAGE, { teams: teamsSelectedFormatted }).then(() => {
          hide();
          this.$store.dispatch(GET_UMFRAGEN);
          this.toast('Umfrage erfolgreich erstellt');
        });
      }
    },
    onHide() {
      this.teamsSelected = [];
    },
  },
};
</script>
<style scoped>
:deep(.modal-footer) {
  padding: 0.8rem;
}
:deep(.modal-header) {
  padding: 1rem;
}
</style>
